import { Link } from "gatsby";
import React from "react";
import * as styles from './GridLinkableImageElement.module.css'
import HandmadeStretchedText from "./HandmadeStretchedText";


const GridLinkableImageElement = ({ children, titleTop = null, titleMiddle = null, titleBottom = null, url = null }) => {

    const TextElement = () => {
        if (titleTop && titleBottom) {
            return (
                <div className={styles.hoverableElement}>
                    <HandmadeStretchedText title={titleTop} />
                    <HandmadeStretchedText title={titleMiddle} />
                    <HandmadeStretchedText title={titleBottom} />
                </div>
            )
        }
        return null
    }

    return (
        <React.Fragment>
            <Link className={styles.linkedImage} to={url}>
                {children}
                <TextElement />
            </Link>
        </React.Fragment>
    )
}

export default GridLinkableImageElement