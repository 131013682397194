import React from 'react';
import GridLinkableImageElement from '../components/GridLinkableImageElement';
import GridLayout from '../components/GridLayout';
import PageLayout from '../components/PageLayout'
import { StaticImage } from "gatsby-plugin-image"
import Seo from '../components/Seo';



const PeojectsPage = () => {

    const projectsComponents = React.Children.toArray([
        <GridLinkableImageElement titleTop="COOKPLAY" titleMiddle="TEARS" titleBottom="COLLECTION" url="/projects/cookplay-tears-collection">
            <StaticImage src="./../images/cookplay-tears/cookplay-tears-0.jpg" alt="" layout="fullWidth"></StaticImage>
        </GridLinkableImageElement>,
        <GridLinkableImageElement titleTop="COOKPLAY" titleMiddle="REX" titleBottom="COLLECTION" url="/projects/cookplay-rex-collection">
            <StaticImage src="./../images/cookplay/cookplay-0.jpg" alt="" layout="fullWidth"></StaticImage>
        </GridLinkableImageElement>,
        <GridLinkableImageElement titleTop="GHOSTPRINT" titleMiddle="ORGANIC" titleBottom="GRIPS" url="/projects/ghostprint-organic-grips">
            <StaticImage src="./../images/ghost-print/ghost-print-0.jpg" alt="" layout="fullWidth"></StaticImage>
        </GridLinkableImageElement>,
        <GridLinkableImageElement titleTop="KEF" titleMiddle="INEAR" titleBottom="MU3" url="/projects/kef-inear-mu3">
            <StaticImage src="./../images/kefmu3/kefmu3-0.jpg" alt="" layout="fullWidth"></StaticImage>
        </GridLinkableImageElement>,
        <GridLinkableImageElement titleTop="MAMEHA" titleMiddle="OILWALLET" titleBottom="DISPENSER" url="/projects/mameha-oilwallet-dispenser">
            <StaticImage src="./../images/mameha/mameha-0.jpg" alt="" layout="fullWidth"></StaticImage>
        </GridLinkableImageElement>,
        <GridLinkableImageElement titleTop="MAMEHA" titleMiddle="FACEDRY" titleBottom="BRUSH" url="/projects/mameha-facedry-brush">
            <StaticImage src="./../images/mameha/mameha-brush-0.jpg" alt="" layout="fullWidth"></StaticImage>
        </GridLinkableImageElement>,
        <GridLinkableImageElement titleTop="FUTUREAIR" titleMiddle="SAM2.0" titleBottom="SENSOR" url="/projects/futureair-sam20-sensor">
            <StaticImage src="./../images/futureair-sam/futureair-sam-0.jpg" alt="" layout="fullWidth"></StaticImage>
        </GridLinkableImageElement>,
        <GridLinkableImageElement titleTop="PUZHEN" titleMiddle="AROMA" titleBottom="DIFFUSER" url="/projects/puzhen-aromadiffuser">
            <StaticImage src="./../images/puzhen/puzhen-aromadiffuser-0.jpg" alt="" layout="fullWidth"></StaticImage>
        </GridLinkableImageElement>,
        <GridLinkableImageElement titleTop="NATUZZI" titleMiddle="ERGO" titleBottom="COLLECTION" url="/projects/natuzzi-ergo-collection">
            <StaticImage src="./../images/natuzzi/natuzzi-0.jpg" alt="" layout="fullWidth"></StaticImage>
        </GridLinkableImageElement>,
        <GridLinkableImageElement titleTop="YAPE" titleMiddle="LAND" titleBottom="DRONE" url="/projects/yape-land-drone">
            <StaticImage src="./../images/yape/yape-0.jpg" alt="" layout="fullWidth"></StaticImage>
        </GridLinkableImageElement>,
        <GridLinkableImageElement titleTop="SMARTROBOTS" titleMiddle="CONTROL" titleBottom="HEAD" url="/projects/smart-robots-controlhead">
            <StaticImage src="./../images/smart-robots/smart-robots-0.jpg" alt="" layout="fullWidth"></StaticImage>
        </GridLinkableImageElement>,
        <GridLinkableImageElement titleTop="FIGHTECH" titleMiddle="UNDER" titleBottom="GLOVE" url="/projects/fightech-underglove">
            <StaticImage src="./../images/fightech/fightech-0.jpg" alt="" layout="fullWidth"></StaticImage>
        </GridLinkableImageElement>,
        <GridLinkableImageElement titleTop="PROMETEO" titleMiddle="PERFUSION" titleBottom="MACHINE" url="/projects/prometeo-perfusion-machine">
            <StaticImage src="./../images/prometeo/prometeo-0.jpg" alt="" layout="fullWidth"></StaticImage>
        </GridLinkableImageElement>,
    ])

    return (
        <PageLayout secondHead="Projects" withFooter={true}>
            <GridLayout components={projectsComponents}></GridLayout>
            <Seo subTitle="Projects" />
        </PageLayout>
    )
}

export default PeojectsPage